
  .space-top {
    margin-top: 30px;
  }
  .eventcard .card[data-background] {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px; }

    .card .card-icon img {
        font-size: 40px;
        width: 105px;
        border: 2px solid #ccc;
        border-radius: 50%;
        height: 105px;
        line-height: 105px; }
.filter-grey{
            filter: invert(93%) sepia(1%) saturate(0%) hue-rotate(97deg) brightness(90%) contrast(88%);
}
        