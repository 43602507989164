/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('~assets/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('~assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('~assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('~assets/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('~assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('~assets/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url('~assets/fonts/MaterialIcons-Regular.woff2') format('woff2'),
      url('~assets/fonts/MaterialIcons-Regular.woff') format('woff'),
      url('~assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
  }