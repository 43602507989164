.image-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 0.5rem;
  align-items: flex-start;
}

.image-list .cardContainer {
  width: 300px;
}
